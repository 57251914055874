import React, { useEffect, useState } from "react";
import SEO from "../components/seo/seo";
import RotatingCircularText from "../components/rotating-circular-text/RotatingCircularText";
import Swiper from "swiper";

// import {Link} from "gatsby";
import '../layouts/extra.css';
// import "../swiper/swiper.js";

// https://codepen.io/Neilblaze/pen/dyqmRRz

const ActivityPage = () => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const swiper = new Swiper(".swiper-container", {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      centeredSlides: true,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 50,
        modifier: 10,
        slideShadows: true
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      loop: true,
      loopedSlides: 7,
    });
  }, []);

  

return (
  <>
    <SEO title="Achievements" />
    <section className="text-gray-600 body-font z-40 staticDiv scrollbar-hide">
    <div className="container px-4 py-12 mx-auto">
    
    <div className="flex flex-wrap -m-30">
    <div className="flex flex-wrap -m-1 md:-m-2">
      <div className="flex flex-wrap w-1/2">


      {/* For Mobile Devices [Temporary Fix] */}
      <div className="w-1/2 p-1 md:p-2 relative emage md:hidden">
        <a href="https://devpost.com/software/fintruist" target="_blank" rel="noopener noreferrer">
          <img alt="fintruist" className="block object-cover object-center w-full h-full rounded-lg transition ease-in-out delay-130 hover:-translate-y-1 hover:scale-105 duration-300 hover:shadow-md border-solid border-2 border-red-100"
            src="https://user-images.githubusercontent.com/48355572/212498181-7e470c55-c204-4192-9b46-8accd27df5f5.gif"/>
            <div className="absolute top-1 right-1 h-8 w-8 ..."><img src="https://cdn-icons-png.flaticon.com/512/189/189668.png"/></div>
        </a>
      </div>
      <div className="w-1/2 p-1 md:p-2 relative emage md:hidden">
        <a href="https://devpost.com/software/zentube" target="_blank" rel="noopener noreferrer"><img alt="zentube" className="block object-cover object-center w-full h-full rounded-lg transition ease-in-out delay-130 hover:-translate-y-1 hover:scale-105 duration-300 hover:shadow-md border-solid border-2 border-purple-900"
            src="https://user-images.githubusercontent.com/48355572/212498215-0eae823d-43c8-4085-8bc4-d73d14ea7204.gif"/>
            <div className="absolute top-1 right-1 h-8 w-8 ..."><img src="https://cdn-icons-png.flaticon.com/512/189/189668.png"/></div>    
        </a> 
      </div>
      <div className="w-full p-1 md:p-2 relative emage md:hidden">
        <a href="https://devpost.com/software/ziro" target="_blank" rel="noopener noreferrer"><img alt="ziro" className="block object-cover object-center w-full h-full rounded-lg transition ease-in-out delay-130 hover:-translate-y-1 hover:scale-103 duration-300 hover:shadow-md border-solid border-2 border-emerald-500"
            src="https://user-images.githubusercontent.com/48355572/212498243-816f64a0-4098-47f4-982c-e0a47c9a6123.gif"/>
            <div className="absolute top-1 right-1 h-8 w-8 ..."><img src="https://cdn-icons-png.flaticon.com/512/189/189668.png"/></div>
        </a>
      </div>

      <div className="w-full md:hidden">
        <center><p> Work in Progress </p></center>
      </div>

      {/* For Desktop Devices [Temporary Fix] */}
      <div className=" p-1 md:p-2 hidden md:block">
        <div className="swiper-container" 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
          <div className="swiper-wrapper">

            <div 
            className="swiper-slide hover:shadow-md border-solid border-2 border-orange-400" 
            style={{backgroundImage: 'url(https://user-images.githubusercontent.com/48355572/212498181-7e470c55-c204-4192-9b46-8accd27df5f5.gif)'}}
            onClick={() => window.open('https://devpost.com/software/fintruist', '_blank')}
            ><div className="absolute top-0.5 right-0.5 h-8 w-8 ..."><img src="https://user-images.githubusercontent.com/48355572/224995641-360e8d2b-a5c3-47ae-a108-15638c2eef30.png"/></div></div>

            <div 
            className="swiper-slide hover:shadow-md border-solid border-2 border-blue-300" 
            style={{backgroundImage: 'url(https://user-images.githubusercontent.com/48355572/224996715-d6ab5a3a-6180-4c78-85a8-64fbbecd6673.gif)'}}
            onClick={() => window.open('https://devpost.com/software/renteasy', '_blank')}
            ><div className="absolute top-0.5 right-0.5 h-8 w-8 ..."><img src="https://user-images.githubusercontent.com/48355572/224995641-360e8d2b-a5c3-47ae-a108-15638c2eef30.png"/></div></div>

            <div 
            className="swiper-slide hover:shadow-md border-solid border-2 border-red-300" 
            style={{backgroundImage: 'url(https://user-images.githubusercontent.com/48355572/224986347-4aff61b7-b64e-4c2b-a6eb-167f243bcad6.gif)'}}
            onClick={() => window.open('https://devpost.com/software/up-cycle', '_blank')}
            ><div className="absolute top-0.5 right-0.5 h-8 w-8 ..."><img src="https://user-images.githubusercontent.com/48355572/224995641-360e8d2b-a5c3-47ae-a108-15638c2eef30.png"/></div></div>

            <div 
            className="swiper-slide hover:shadow-md border-solid border-2 border-gray-400" 
            style={{backgroundImage: 'url(https://user-images.githubusercontent.com/48355572/224986572-8150de81-ed98-4461-93d1-d531bddb3e62.gif)'}}
            onClick={() => window.open('https://devpost.com/software/eyeballin', '_blank')}
            ><div className="absolute top-0.5 right-0.5 h-8 w-8 ..."><img src="https://user-images.githubusercontent.com/48355572/224995641-360e8d2b-a5c3-47ae-a108-15638c2eef30.png"/></div></div>

            <div 
            className="swiper-slide hover:shadow-md border-solid border-2 border-orange-600" 
            style={{backgroundImage: 'url(https://user-images.githubusercontent.com/48355572/224993602-b81e3e04-b106-4c43-a9e5-6ddacf9b014c.gif)'}}
            onClick={() => window.open('https://devfolio.co/projects/studysurf-5ec7', '_blank')}
            ><div className="absolute top-0.5 right-0.5 h-8 w-8 ..."><img src="https://user-images.githubusercontent.com/48355572/224995641-360e8d2b-a5c3-47ae-a108-15638c2eef30.png"/></div></div>

            <div 
            className="swiper-slide hover:shadow-md border-solid border-2 border-slate-500" 
            style={{backgroundImage: 'url(https://user-images.githubusercontent.com/48355572/212498258-f6f02642-e0e1-4547-8ae0-160cd5eb6b0a.gif)'}}
            onClick={() => window.open('https://devpost.com/software/unisight', '_blank')}
            ><div className="absolute top-0.5 right-0.5 h-8 w-8 ..."><img src="https://user-images.githubusercontent.com/48355572/224995641-360e8d2b-a5c3-47ae-a108-15638c2eef30.png"/></div></div>

            <div 
            className="swiper-slide hover:shadow-md border-solid border-2 border-emerald-500" 
            style={{backgroundImage: 'url(https://user-images.githubusercontent.com/48355572/212498243-816f64a0-4098-47f4-982c-e0a47c9a6123.gif)'}}
            onClick={() => window.open('https://devpost.com/software/ziro', '_blank')}
            ><div className="absolute top-0.5 right-0.5 h-8 w-8 ..."><img src="https://user-images.githubusercontent.com/48355572/224995641-360e8d2b-a5c3-47ae-a108-15638c2eef30.png"/></div></div>

            <div 
            className="swiper-slide hover:shadow-md border-solid border-2 border-yellow-300" 
            style={{backgroundImage: 'url(https://user-images.githubusercontent.com/48355572/225015250-2c46ca97-445b-4c2b-99a7-3edd6357d111.gif)'}}
            onClick={() => window.open('https://devpost.com/software/quikserve', '_blank')}
            ><div className="absolute top-0.5 right-0.5 h-8 w-8 ..."><img src="https://user-images.githubusercontent.com/48355572/224995641-360e8d2b-a5c3-47ae-a108-15638c2eef30.png"/></div></div>

          </div>
        </div>

      </div>

      {isHovered && (
      <div className="w-full -mt-56 -ml-20 relative pointer-events-none	z-50">
      <RotatingCircularText/>
      </div>
      )}
        
      <div className="w-full p-1 md:p-2 relative emage">
        {/* <a href="https://devpost.com/software/ziro" target="_blank" rel="noopener noreferrer"><img alt="ziro" className="block object-cover object-center w-full h-full rounded-lg transition ease-in-out delay-130 hover:-translate-y-1 hover:scale-103 duration-300 hover:shadow-md border-solid border-2 border-emerald-500"
            src="https://user-images.githubusercontent.com/48355572/212498243-816f64a0-4098-47f4-982c-e0a47c9a6123.gif"/>
            <div className="absolute top-1 right-1 h-8 w-8 ..."><img src="https://cdn-icons-png.flaticon.com/512/189/189668.png"/></div>
        </a> */}

      {/* Astrophotography Section  */}
      <div className="css-carousel hidden md:block">
        <img alt="Milky Way" className="css-img block object-cover object-center w-full h-full rounded-lg transition ease-in-out delay-130 hover:-translate-y-1 hover:scale-103 duration-300 hover:shadow-md border-solid border-2 pointer-events-none border-yellow-500" src="https://github.com/google/flax/assets/48355572/1b622e23-6634-4252-add4-c89c42b01054" />
        <img alt="Milky Way 2" className="css-img block object-cover object-center w-full h-full rounded-lg transition ease-in-out delay-130 hover:-translate-y-1 hover:scale-103 duration-300 hover:shadow-md border-solid border-2 pointer-events-none border-yellow-500" src="https://res.cloudinary.com/dutfy6mlc/image/upload/v1694784967/rawas1jwdok3dihpoioe.webp" />
        <img alt="Star Trail" className="css-img block object-cover object-center w-full h-full rounded-lg transition ease-in-out delay-130 hover:-translate-y-1 hover:scale-103 duration-300 hover:shadow-md border-solid border-2 pointer-events-none border-yellow-500" src="https://github.com/google/flax/assets/48355572/42df05e0-2364-41a8-8278-bed7ee148edd" />
        <img alt="Moon x Venus" className="css-img block object-cover object-center w-full h-full rounded-lg transition ease-in-out delay-130 hover:-translate-y-1 hover:scale-103 duration-300 hover:shadow-md border-solid border-2 pointer-events-none border-yellow-500" src="https://github.com/google/flax/assets/48355572/39938166-0cdc-4b40-af17-2db4f0d3b55a" />
        <img alt="Milky Way" className="css-img block object-cover object-center w-full h-full rounded-lg transition ease-in-out delay-130 hover:-translate-y-1 hover:scale-103 duration-300 hover:shadow-md border-solid border-2 pointer-events-none border-yellow-500" src="https://github.com/google/flax/assets/48355572/ea078b21-690d-44d7-8cda-59dc0da79256" />
        <img alt="Oeion Nebula Messier 42" className="css-img block object-cover object-center w-full h-full rounded-lg transition ease-in-out delay-130 hover:-translate-y-1 hover:scale-103 duration-300 hover:shadow-md border-solid border-2 pointer-events-none border-yellow-500" src="https://github.com/google/flax/assets/48355572/8f322220-18b5-46c4-96d1-1c2b1327a2b6" />
        
        {/* <div className="absolute bottom-2 left-2 h-18 w-8 ..."><img src="https://github.com/google/flax/assets/48355572/d61f4223-6eea-4900-b92f-2357b876f4b0"/></div> */}
        
          <div className="absolute bottom-3 left-3 h-18 w-11 ...">
            <img src="https://github.com/google/flax/assets/48355572/d61f4223-6eea-4900-b92f-2357b876f4b0"/>
          </div>
          <div className="absolute bottom-3 left-16 text-white">
            Daytime Hacker 👨‍💻 <br/> Nocturnal Astrophotographer ✨
          </div>
        
        {/* https://play.tailwindcss.com/5nzLKbNPD9 */}

      </div>

      </div>
      </div>

      <div className="flex flex-wrap w-1/2">
        <div className="w-full p-1 md:p-2 rounded-lg">
          <img alt="spotify_nowPlaying" className="block object-cover object-center w-full h-full rounded-2xl border-solid border-1 bg-gradient-to-r p-[4px] from-emerald-400 via-blue-500 to-purple-500 pointer-events-none"
            src="https://spotify-recently-played-readme.vercel.app/api?user=31okuur5huofrrd3n6y6v2gyrjx4&width=300&count=3"/>
        </div>

        <div className="w-1/2 p-1 md:p-2 relative emage">
          <div><img alt="location" className="block object-cover object-center w-full h-full rounded-lg transition ease-in-out delay-130 hover:-translate-y-1 hover:scale-105 duration-300 hover:shadow-md pointer-events-none border-solid border-2 border-slate-500"
            src="https://res.cloudinary.com/dutfy6mlc/image/upload/v1694689871/jurf5wgqqtvcifv5xahm.webp"/>
            <div className="absolute pointer-events-none top-0 right-1 h-6 w-6 ..."><img src="https://github.com/google/mediapipe/assets/48355572/e98aea70-f58d-441a-9706-76c1ac5fa8ac"/></div>
          </div>
        </div>
        <div className="w-1/2 p-1 md:p-2">
          <a href="https://blog.neilblaze.live"><div><img alt="blog" className="block object-cover object-center w-full h-full rounded-lg border-solid border-2 border-rose-100 pointer-events-none"
            src="https://user-images.githubusercontent.com/48355572/267954280-01909c52-ef4e-4a72-9a13-bccb24cb4580.png"/></div>
          </a>
        </div>
      </div>
      </div>
      </div>
    </div>
  </section>
  </>
  );
};

export default ActivityPage;